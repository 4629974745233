import { atom, useSetAtom, useAtomValue, useAtom } from "jotai";

export const cartProjectionAtom = atom<{ loading?: boolean } & CartProjection>({
  loading: false
});

export const useCartProjectionAtomValue = () =>
  useAtomValue(cartProjectionAtom);
export const useCartProjectionSetAtom = () => useSetAtom(cartProjectionAtom);
export const useCartProjectionAtom = () => useAtom(cartProjectionAtom);

export default useCartProjectionAtom;
