import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { UseRequestProvider } from "@RHCommerceDev/custom-providers/Provider";

export const useCustomLocation = () => {
  const req: { path?: string } = useContext(UseRequestProvider);

  const location = useLocation();

  return {
    pathname: processEnvServer ? req?.path : location?.pathname
  };
};
