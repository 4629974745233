import { ApolloError, ApolloQueryResult, useQuery } from "@apollo/client";
import { querySkuAvailabilityStatus } from "@RHCommerceDev/graphql-client-sku-availability/queries/sku-availability-status";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";

type UseQuantity = {
  postalCode?: Maybe<string>;
  /** @deprecated we use the useCountry hook internally */
  country?: string | undefined;
  sku: Maybe<string>;
  maxQuantity?: Maybe<number> | null;
  defaultQuantity?: number;
  skip?: boolean;
};

export const useQuantity = ({
  postalCode,
  sku,
  maxQuantity,
  defaultQuantity = 1,
  skip = false
}: UseQuantity): {
  loading: boolean;
  skuAvailabilityStatus: Maybe<SkuAvailabilityStatusType>;
  maxQuantity: number;
  error: ApolloError | undefined;
  refetchQty?: (
    variables?: Partial<QuerySkuAvailabilityStatusArgs> | undefined
  ) => Promise<ApolloQueryResult<Pick<Query, "skuAvailabilityStatus">>>;
} => {
  const env = useEnv();
  const country = useCountry();
  const {
    loading,
    data: { skuAvailabilityStatus } = {},
    error,
    refetch: refetchQty
  } = useQuery<
    Pick<Query, "skuAvailabilityStatus">,
    QuerySkuAvailabilityStatusArgs
  >(querySkuAvailabilityStatus, {
    variables: { country, sku: sku || "SKU_MISSING_UI", postalCode },
    skip: !yn(env.FEATURE_USE_QUANTITY) || !sku || skip
  });
  return {
    loading,
    skuAvailabilityStatus,
    maxQuantity:
      (maxQuantity && skuAvailabilityStatus?.maxQuantity
        ? Math.min(skuAvailabilityStatus?.maxQuantity, maxQuantity)
        : skuAvailabilityStatus?.maxQuantity) || defaultQuantity,
    error,
    refetchQty
  };
};
