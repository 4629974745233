import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import { useLocation } from "react-router";
import { useMemo } from "react";

/** It has a token in the param and isEstore */
export function useIsPaymentPortalEstore() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const token = useMemo(() => {
    return (
      searchParams.get("token") || searchParams.get("paymentPortalToken") || ""
    );
  }, [searchParams]);
  const paymentId = useMemo(() => {
    return searchParams.get("paymentId");
  }, [searchParams]);
  const { isEstore } = useAppId();
  const isPaymentPortalEstore = (!!token || !!paymentId) && isEstore;
  return {
    isPaymentPortalEstore
  };
}
