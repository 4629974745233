import { useQuery } from "@apollo/client";
import { queryProductImage } from "@RHCommerceDev/graphql-client/queries/product-image";
import { useMemo } from "react";

export function useProductImage({
  productId,
  options,
  fullSkuId,
  siteId,
  locale,
  skip = false
}: {
  productId: Maybe<string>;
  options: Maybe<{ id?: Maybe<string> }[]> | string[];
  fullSkuId?: Maybe<string>;
  siteId?: Maybe<string>;
  locale?: Maybe<string>;
  skip?: boolean;
}) {
  const selectedOptionIds = useMemo<string[]>(() => {
    return (options || [])
      .map(({ id = "" } = { id: "" }) => id)
      .filter(id => id) as string[];
  }, [options]);
  const {
    data,
    loading,
    error,
    refetch: refetchLineItemImage
  } = useQuery<Pick<Query, "productImage">, QueryProductImageArgs>(
    queryProductImage,
    {
      variables: {
        selectedOptionIds:
          (selectedOptionIds || []).length > 0 ? selectedOptionIds : null,
        productId: productId || "MISSING_PRODUCT_ID",
        fullSkuId,
        siteId,
        locale
      },
      skip: !productId || skip
    }
  );
  return {
    imageUrl: data?.productImage?.imageUrl,
    loading,
    error,
    refetchLineItemImage
  };
}
