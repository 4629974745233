import { atom, useAtomValue, useSetAtom } from "jotai";

export const checkoutAddressSubmitButtonAtom = atom<boolean>(false);

export const useCheckoutAddressSubmitButton = () =>
  useAtomValue(checkoutAddressSubmitButtonAtom);
export const useCheckoutAddressSetButton = () =>
  useSetAtom(checkoutAddressSubmitButtonAtom);

export default {
  useCheckoutAddressSubmitButton,
  useCheckoutAddressSetButton
};
