import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { atom, useSetAtom, useAtomValue, useAtom } from "jotai";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import { useKeycloak } from "@RHCommerceDev/utils/Keycloak/KeyCloak";
import { useMemo } from "react";
import { useCartProjectionAtomValue } from "./useCartProjectionAtom";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

export const userSessionAtom = atom<
  SessionUserType & {
    loading: boolean;
    lastRequestId?: string;
    lastRequestIdToCompare?: string;
    loadingUpdateUserSession?: boolean;
    isMyAccountIconDisabled?: boolean;
    anonCartId?: string | null;
    prevLoadingCall?: number;
    isCartMerge?: boolean;
    mergeCartLoading?: boolean;
  }
>({
  loading: true,
  loadingUpdateUserSession: false,
  isMyAccountIconDisabled: false,
  lastRequestId: "",
  lastRequestIdToCompare: "",
  anonCartId: null,
  prevLoadingCall: 0,
  isCartMerge: false,
  mergeCartLoading: false,
  rhUser: {
    userType: "ANONYMOUS",
    firstName: "",
    lastName: "",
    email: ""
  }
});

export const useUserSessionAtomValue = () => {
  const env = useEnv();
  const { id: cartProjectionCartId } = useCartProjectionAtomValue();
  const userSession = useAtomValue(
    useMemo(
      () =>
        atom(get => {
          const s = get(userSessionAtom);
          return {
            ...s,
            currentCartId: yn(env.FEATURE_CART_ID_ATOM)
              ? cartProjectionCartId
              : s?.currentCartId
          };
        }),
      [cartProjectionCartId, env.FEATURE_CART_ID_ATOM]
    )
  );
  const { keycloak } = useKeycloak();

  if (!processEnvServer) {
    const storedSessionDetails = memoryStorage.getItem(
      "analytics-sessionDetails"
    );
    const hasUniqueValues =
      storedSessionDetails?.authenticated !== keycloak?.authenticated ||
      storedSessionDetails?.rhUser !== userSession?.rhUser ||
      storedSessionDetails?.membershipInfo !== userSession?.membershipInfo ||
      storedSessionDetails?.cookiePreferences !==
        userSession?.cookiePreferences;
    if (hasUniqueValues) {
      memoryStorage.setItem("analytics-sessionDetails", {
        authenticated: keycloak?.authenticated,
        rhUser: userSession?.rhUser,
        membershipInfo: userSession?.membershipInfo,
        cookiePreferences: userSession?.cookiePreferences,
        validAssociate: userSession?.validAssociate
      });
    }
  }

  return userSession;
};

export const useUserSessionSetAtom = () => useSetAtom(userSessionAtom);
export const useUserSessionAtom = () => useAtom(userSessionAtom);

export default useUserSessionAtom;
