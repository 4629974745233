import { useMemo } from "react";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { useLocation } from "react-router";

export function useIsCartPages() {
  const req = getReqContext();
  const location = useLocation();
  const currentPath = processEnvServer ? req?.path : location.pathname;

  return useMemo(() => {
    const checkPath = path => currentPath?.includes(path) || false;

    const isConfirmationPage = checkPath("confirmation.jsp");
    const isOrderReviewPage = checkPath("order_review.jsp");
    const isPaymentPage = checkPath("payment.jsp");
    const isAddressPage = checkPath("address.jsp");
    const isCheckoutSignInRegisterPage = checkPath(
      "checkout-sign-in-register.jsp"
    );

    const isCheckoutPages =
      isConfirmationPage ||
      isOrderReviewPage ||
      isPaymentPage ||
      isAddressPage ||
      isCheckoutSignInRegisterPage;

    return {
      isShoppingCartPage: checkPath("shopping_cart.jsp"),
      isConfirmationPage,
      isOrderReviewPage,
      isPaymentPage,
      isAddressPage,
      isCheckoutSignInRegisterPage,
      isCheckoutPages
    };
  }, [currentPath]);
}
