import { useCallback, useContext, useMemo } from "react";

import { goToSelectedCountryUrl } from "@RHCommerceDev/utils/getCountryFromUrl";
import { FetchResult, useMutation } from "@apollo/client";
import {
  switchWishlistRegion,
  queryGetWishlist
} from "@RHCommerceDev/graphql-client/queries/wishlist";
import { useHistory } from "react-router";

import {
  COUNTRY_COOKIE,
  MEASUREMENT_COOKIE,
  LANGUAGE_COOKIE,
  POSTAL_CODE_COOKIE,
  REQUIRED_PERMISSION_COUNTRIES
} from "@RHCommerceDev/utils/constants";

import {
  UserPreferencesContext,
  ProviderContext,
  PreviousState
} from "@RHCommerceDev/custom-providers/UserPreferencesProvider";
import yn from "yn";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useUpdateCart } from "@RHCommerceDev/hooks/useUpdateCart";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { countries } from "@RHCommerceDev/resources/countries-config.json";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import { useAppId } from "@RHCommerceDev/hooks-use-app-id";
import { useCookies } from "react-cookie";
import { useGetCartProjectLazyQuery } from "@RHCommerceDev/hooks-queries";
import { useUserSessionAtomValue } from "@RHCommerceDev/hooks-atoms";
import { isRegisteredUserType } from "@RHCommerceDev/utils/isRegisteredUserType";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import isEmpty from "lodash.isempty";
import { getCookie } from "@RHCommerceDev/utils/cookies";
import { Maybe } from "graphql/jsutils/Maybe";

function getCookieRulesCountry(
  sessionCookieRulesCountry: string,
  selectedCountry: string,
  prevCountry: string
) {
  if (sessionCookieRulesCountry) {
    return sessionCookieRulesCountry;
  } else {
    if (prevCountry !== selectedCountry) return selectedCountry;
    return "US";
  }
}

const useUserPreferences = (
  callback?: () => void
): Omit<ProviderContext, "updatePreviousState"> & {
  handleSaveCookies: (
    options?: Partial<PreviousState> & { postalCode?: string },
    freshUserSession?: SessionUserType,
    forceUpdate?: boolean,
    shouldRedirect?: boolean
  ) => void;
  setCookie: ReturnType<typeof useCookiesWithPermission>["setCookieWrapper"];
  cookiePreferences: Maybe<CookiePreferencesType>;
  previousState: PreviousState;
  updatePreviousState: () => void;
  isDirty: boolean;
} => {
  let {
    country,
    measurement,
    language,
    setCountry,
    setLanguage,
    setMeasurement,
    updatePreviousState,
    previousState,
    setCartUpdateLoading,
    cart,
    setCart,
    prevCountry,
    setPrevCountry
  } = useContext(UserPreferencesContext);
  const history = useHistory();
  const env = useEnv();

  const userSession = useUserSessionAtomValue();
  const [getCartProjection] = useGetCartProjectLazyQuery();
  const pc = getCookie("pc");
  const { isConcierge } = useAppId();
  const [, setCookie] = useCookies([
    COUNTRY_COOKIE,
    LANGUAGE_COOKIE,
    MEASUREMENT_COOKIE
  ]);
  const req = getReqContext();

  const isShoppingCart = useMemo(() => {
    try {
      return (
        (!processEnvServer
          ? window?.location?.pathname?.includes("/checkout/shopping_cart.jsp")
          : req?.path?.includes("/checkout/shopping_cart.jsp")) || false
      );
    } catch (error) {
      return false;
    }
  }, [req?.path]);
  const isCheckout = useMemo(() => {
    try {
      return (
        (!processEnvServer
          ? window?.location?.pathname?.includes("/checkout")
          : req?.path?.includes("/checkout")) || false
      );
    } catch (error) {
      return false;
    }
  }, [req?.path]);

  const { updateCart } = useUpdateCart({
    callerId: "USE_USER_PREFERENCES",
    onCompleted: async data => {
      setCartUpdateLoading?.(false);
      if (!isEmpty(data)) {
        setCart(
          isConcierge
            ? (data as any)?.updateConciergeCart
            : (data as any)?.updateCart
        );
      }
      getCartProjection();
    },
    onError: () => setCartUpdateLoading?.(false)
  });

  const [updateWishlist] = useMutation<Mutation>(switchWishlistRegion, {
    onCompleted: async () => {}
  });

  const { setCookiePermissionCountry, setCookieWrapper, clearStoragesValues } =
    useCookiesWithPermission();

  const isDirty = useMemo(() => {
    const stateObject = {
      country,
      language,
      measurement
    };

    return JSON.stringify(previousState) !== JSON.stringify(stateObject);
  }, [country, language, measurement, previousState]);

  const handleSaveCookies = useCallback(
    async (
      selectedOptions?: Partial<PreviousState> & { postalCode?: string },
      freshUserSession?: SessionUserType,
      forceUpdate?: boolean,
      shouldRedirect = true
    ) => {
      const selectedCountry = selectedOptions?.country || country;
      const selectedLanguage = selectedOptions?.language || language;
      const selectedMeasurement = selectedOptions?.measurement || measurement;
      const postalCode =
        selectedOptions?.postalCode ||
        countries[selectedCountry]?.defaultValues?.postalCode;
      const { rhUser, currentCartId, cookiePreferences } =
        freshUserSession || userSession;

      const context = {
        fetchOptions: {
          method: "POST"
        }
      };

      const cookieRulesCountry = getCookieRulesCountry(
        freshUserSession?.cookiePreferences?.cookieRules ||
          userSession?.cookiePreferences?.cookieRules ||
          "",
        selectedCountry,
        previousState.country
      );

      if (isConcierge) {
        setCookie(COUNTRY_COOKIE, selectedCountry, { path: "/" });
      } else {
        setCookieWrapper(
          COUNTRY_COOKIE,
          selectedCountry,
          {},
          cookieRulesCountry
        );
      }

      if (
        yn(cookiePreferences?.preferencesFunctionalityCookie) ||
        forceUpdate
      ) {
        if (isConcierge) {
          setCookie(MEASUREMENT_COOKIE, selectedMeasurement, { path: "/" });
          setCookie(LANGUAGE_COOKIE, selectedLanguage, { path: "/" });
        } else {
          setCookieWrapper(
            MEASUREMENT_COOKIE,
            selectedMeasurement,
            {},
            cookieRulesCountry
          );
          setCookieWrapper(
            LANGUAGE_COOKIE,
            selectedLanguage,
            {},
            cookieRulesCountry
          );
        }
      }

      const isCountryChanged =
        previousState.country !== selectedCountry || Boolean(freshUserSession);
      const isLanguageChanged = previousState.language !== selectedLanguage;

      if (isCountryChanged) {
        setPrevCountry(previousState.country);
      }

      /**
       * created an array of promises to execute in parallel.
       */
      const promises: Promise<
        FetchResult<Mutation, Record<string, any>, Record<string, any>>
      >[] = [];
      if (isCountryChanged || forceUpdate) {
        updatePreviousState();
        if (previousState.country !== selectedCountry) {
          memoryStorage.setItem("isCountryChanged", true);
        }
        if (shouldRedirect)
          goToSelectedCountryUrl(selectedCountry, history, selectedLanguage);
        if (
          REQUIRED_PERMISSION_COUNTRIES.includes(cookieRulesCountry) &&
          !cookiePreferences?.cookieRules
        ) {
          sessionStorage.removeItem("isGTMLoaded");
          setTimeout(() => {
            setCookiePermissionCountry(cookieRulesCountry);
          }, 5000);
          clearStoragesValues(cookieRulesCountry, cookiePreferences);
        }

        setCookieWrapper(
          POSTAL_CODE_COOKIE,
          postalCode,
          {},
          cookieRulesCountry
        );

        const pushCartUpdateToPromises = async () => {
          setCartUpdateLoading?.(true);
          promises?.push(
            (await updateCart({
              country: selectedCountry,
              postalCode,
              currentCartId,
              rhUser
            })) as typeof promises[0]
          );
          setCartUpdateLoading?.(false);
        };
        //only update cart when cart broker service is enabled
        // And also let's not update the cart when we checkout is in progress
        if (isShoppingCart) {
          /** Don't update the shopping_cart if it already has the correct country and postalCode */
          if (selectedCountry !== country || postalCode !== pc) {
            pushCartUpdateToPromises();
          }
        } else if (!isCheckout) {
          pushCartUpdateToPromises();
        }

        if (
          rhUser?.id &&
          rhUser?.email &&
          isRegisteredUserType(rhUser?.userType)
        ) {
          promises?.push(
            updateWishlist({
              context,
              variables: {
                email: rhUser?.email || "",
                userId: rhUser?.id,
                country: selectedCountry
              },
              update(cache, result) {
                cache.writeQuery({
                  query: queryGetWishlist,
                  variables: {
                    email: rhUser?.email || "",
                    userId: rhUser?.id
                  },
                  data: {
                    getWishlist: result?.data?.switchWishlistRegion
                  }
                });
              }
            })
          );
        }

        await Promise?.allSettled(promises);

        dispatchEvent(
          new CustomEvent("country", { detail: { country: selectedCountry } })
        );
      } else if (isLanguageChanged && !isCountryChanged) {
        updatePreviousState();
        if (shouldRedirect)
          goToSelectedCountryUrl(selectedCountry, history, selectedLanguage);
      }
    },
    [
      country,
      language,
      measurement,
      userSession,
      previousState.country,
      previousState.language,
      isConcierge,
      setCookie,
      setCookieWrapper,
      updatePreviousState,
      history,
      isShoppingCart,
      isCheckout,
      clearStoragesValues,
      setCookiePermissionCountry,
      setCartUpdateLoading,
      updateCart,
      pc,
      updateWishlist
    ]
  );

  return {
    country,
    measurement,
    language,
    setCountry,
    setLanguage,
    setMeasurement,
    isDirty,
    handleSaveCookies,
    cookiePreferences: userSession?.cookiePreferences,
    setCookie: setCookieWrapper,
    previousState,
    updatePreviousState,
    cart,
    setCart,
    prevCountry,
    setPrevCountry
  };
};

export default useUserPreferences;
